import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../services/apiClient';
import './AlertCard.css';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

const AlertCard = ({ alert, onDismiss }) => {
  const handleDismiss = async () => {
    try {
      await apiClient.post('/alerts/dismiss', { alert_id: alert.alert_id });
      onDismiss(alert.alert_id); // Trigger the callback to update feed
    } catch (err) {
      console.error('Error dismissing alert:', err.response?.data || err.message);
      alert('Failed to dismiss the alert. Please try again.');
    }
  };

  return (
    <div className="feed-post-card">
      <div className="feed-post-header">
        <div className="feed-post-header-left">
          <img
            src={
              alert.profile_picture
                ? `${STATIC_URL}/${alert.profile_picture}`
                : '/profile.png'
            }
            alt={`${alert.kapper_display_name}'s profile`}
            className="feed-profile-picture"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/profile.png';
            }}
          />
          <div className="feed-post-header-right">
            <h5 className="feed-post-kapper">{alert.kapper_display_name}</h5>
            <span className="feed-post-date">
              {formatDistanceToNow(new Date(alert.created_at), { addSuffix: true })}
            </span>
          </div>
        </div>
        <button className="dismiss-alert-button" onClick={handleDismiss}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="feed-alert-content">
        <h5 className="feed-post-title">{alert.title}</h5>
        <p className="alert-body">{alert.body}</p>
        {alert.image && (
          <img
            src={`${STATIC_URL}/${alert.image}`}
            alt="Alert"
            className="feed-alert-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-image.png';
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AlertCard;
