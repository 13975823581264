import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import apiClient from '../../../services/apiClient';
import './KapperDiscordCard.css';

const KapperDiscordCard = ({ openLoginModal }) => {
  const { username } = useParams();
  const [connected, setConnected] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Discord connection status
        const discordResponse = await apiClient.get(`kappers/status/${username}`);
        setConnected(discordResponse.data.connected);

        // Check subscription status
        const token = localStorage.getItem('accessToken');
        if (token) {
          const subscriptionsResponse = await apiClient.get('/subscription/subscriptions');
          const subscriptions = subscriptionsResponse.data || [];
          const isUserSubscribed = subscriptions.some(
            (subscription) => subscription.username === username
          );
          setIsSubscribed(isUserSubscribed);
        }
      } catch (error) {
        console.error('Error fetching data:', error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchData();
    }
  }, [username]);

  const handleJoin = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      // Open the login modal if user is not logged in
      openLoginModal('login');
      return;
    }

    try {
      // Redirect to Discord server (this would depend on your implementation)
      console.log('Redirecting user to Discord server...');
    } catch (error) {
      console.error('Error joining Discord:', error.response?.data || error.message);
    }
  };

  if (loading || !username) {
    return null; // Show nothing while loading or if username is missing
  }

  if (!connected) {
    return null; // Show nothing if Discord is not connected
  }

return (
  <>
    <div className="feed-post-divider">
      <hr className="divider-line" />
      <span className="divider-text">Subscription Includes</span>
      <hr className="divider-line" />
    </div>
    <div className="card">
      <div className="card-margin discord-message">
        <FontAwesomeIcon icon={faDiscord} className="black-discord-icon" />
        <span>Premium Discord Access</span>
      </div>
      <button
        className={`locked-button ${isSubscribed ? 'join-now' : ''}`}
        onClick={isSubscribed ? handleJoin : null}
      >
        {isSubscribed ? (
          <>Join Now</>
        ) : (
          <>
            <FontAwesomeIcon icon={faLock} className="lock-icon" /> Locked
          </>
        )}
      </button>
    </div>
  </>
);

};

export default KapperDiscordCard;
