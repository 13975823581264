import React, { useEffect, useState, useMemo } from 'react';
import apiClient from '../../../services/apiClient';
import KapperPostCard from '../../../components/cards/KapperPostCard/KapperPostCard';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBed} from '@fortawesome/free-solid-svg-icons';
import './KapperSettledFeed.css';

function KapperSettledFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchKapperSettledFeed = async (limit, offset) => {
    setLoading(true);
    try {
      const response = await apiClient.get('/feed/kapperSettledFeed', {
        params: { limit, offset },
      });

      setPosts((prevPosts) => [
        ...prevPosts,
        ...response.data.map((post) => ({
          ...post,
          bet_legs: post.bet_legs ? post.bet_legs.split(';') : [], // Parse bet_legs into an array
        })),
      ]);
      setHasMore(response.data.length === limit); // Check if there are more posts to load
    } catch (err) {
      console.error('Error fetching Kapper Settled Feed:', err.message);
      setError('Failed to load feed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKapperSettledFeed(5, 0); // Initial load of 5 posts
  }, []);

  useEffect(() => {
    if (page > 0) {
      fetchKapperSettledFeed(5, page * 5); // Load more posts on scroll
    }
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore) {
        setPage((prevPage) => prevPage + 1); // Increment page to load more posts
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  // Group posts by date
  const groupedPosts = useMemo(() => {
    return Object.entries(
      posts.reduce((acc, post) => {
        const postDate = new Date(post.created_at);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        let groupLabel;

        if (postDate.toDateString() === today.toDateString()) {
          groupLabel = 'Today';
        } else if (postDate.toDateString() === yesterday.toDateString()) {
          groupLabel = 'Yesterday';
        } else {
          groupLabel = postDate.toLocaleDateString();
        }

        if (!acc[groupLabel]) acc[groupLabel] = [];
        acc[groupLabel].push(post);
        return acc;
      }, {})
    );
  }, [posts]);

  // Render grouped posts with dividers
  const renderGroupedPosts = () => {
    return groupedPosts.map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((post) =>
          post.is_author ? (
            <KapperPostCard key={post.post_id} post={post} />
          ) : (
            <FeedPostCard key={post.post_id} post={post} />
          )
        )}
      </div>
    ));
  };

  if (loading && posts.length === 0) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (posts.length === 0) {
    return (
      <div className="feed-container">
        <div className="feed-empty"><FontAwesomeIcon icon={faBed} className="bed-icon" /> No settled slips yes.</div>
      </div>
    );
  }

  return (
    <div className="feed-container">
      {renderGroupedPosts()}
      {loading && hasMore && <div className="feed-loading">Loading more...</div>}
    </div>
  );
}

export default KapperSettledFeed;
