import React from 'react';
import './ReactionBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const ReactionBar = ({ totalPlays, onReact, userReacted }) => {
  return (
    <div className="reaction-bar-container" onDoubleClick={onReact}>
      <div
        className={`reaction-totals ${userReacted ? 'reacted' : 'unreacted'}`}
      >
        <FontAwesomeIcon icon={faArrowsRotate} className="reaction-icon" />
        <span className="total-play">
          {userReacted
            ? `Tailed (${totalPlays} Total)`
            : `${totalPlays} ${totalPlays === 1 ? 'Tail' : 'Tails'}`}
        </span>
      </div>
    </div>
  );
};

export default ReactionBar;
