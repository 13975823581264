import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import './AverageOddsByUsername.css'

function AverageOddsByUsername() {
  const { username } = useParams();
  const [averageOdds, setAverageOdds] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAverageOddsByUsername = async () => {
      if (!username) {
        console.error('[ERROR] Username is missing in URL params');
        setError('Username is required.');
        setIsLoading(false);
        return;
      }

      try {
        console.log(`[DEBUG] Fetching average odds for username: ${username}`);
        const response = await apiClient.get(`/stats/kapper/averageOddsByUsername/${username}`);
        const { success, average_odds } = response.data;

        if (!success) {
          throw new Error('Failed to fetch average odds');
        }

        setAverageOdds(parseFloat(average_odds));
      } catch (err) {
        console.error('[ERROR] Error fetching average odds:', err.message || err);
        setError('Failed to load average odds.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAverageOddsByUsername();
  }, [username]);

  const scaleMin = -500;
  const scaleMax = 500;

  const calculateTickerPosition = (odds) => {
    if (odds === null || odds === undefined) return 0;
    const range = scaleMax - scaleMin;
    const position = ((odds - scaleMin) / range) * 100;
    return Math.min(Math.max(position, 0), 100);
  };

  const getRiskTolerance = (odds) => {
    if (odds >= -500 && odds <= -301) return 'Very Low';
    if (odds >= -300 && odds <= -101) return 'Low';
    if (odds >= -100 && odds <= 100) return 'Moderate';
    if (odds >= 101 && odds <= 300) return 'High';
    if (odds >= 301 && odds <= 500) return 'Very High';
    return 'Unknown';
  };

  const tickerPosition = calculateTickerPosition(averageOdds);
  const riskTolerance = averageOdds !== null ? getRiskTolerance(averageOdds) : 'Loading...';

  return (
    <div className="card average-odds-card">
      <div className="card-margin">
        <p className="risk-level-text">
          <strong className="risk-level-label"></strong>{' '}
          <span className="risk-level-value">{riskTolerance} Risk Slips</span>
        </p>
        <div className="average-odds-scale">
          <span className="scale-min">{scaleMin}</span>
          <div
            className="scale-line"
            style={{
              background: `linear-gradient(to right, rgb(40, 202, 4), rgb(255, 255, 0), rgb(255, 0, 0))`,
            }}
          >
            <div
              className="ticker"
              style={{ left: `${tickerPosition}%` }}
            >
<span className="average-odds-value">
  {isLoading
    ? 'Loading...'
    : `${averageOdds > 0 ? '+' : ''}${averageOdds?.toFixed(2)} average slip`}
</span>
            </div>
          </div>
          <span className="scale-max">+{scaleMax}</span>
        </div>
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  );
}

export default AverageOddsByUsername;
