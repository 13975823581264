import React from 'react';
import Subscriptions from '../../../components/accounts/Subscribers/Subscriptions/Subscriptions';
import Email from '../../../components/accounts/Subscribers/Account/Account';
import BankrollInput from '../../../components/accounts/Subscribers/BankrollInput/BankrollInput';
import './Account.css'


function Account() {
  return (
    <div className="account-container">
      <Subscriptions />
    </div>
  );
}

export default Account;