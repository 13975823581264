import React, { useState, useEffect } from 'react';
import KapperFeed from '../../feeds/Kappers/KapperFeed/KapperFeed'; // Adjust the path based on your file structure
import KapperSettledFeed from '../../feeds/Kappers/KapperSettledFeed/KapperSettledFeed';
import './KapperStats.css';

function KapperStats() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="kapperstats-container">
      <div className="section-header">
        <h2>My Active Slips:</h2>
      </div>
      <KapperFeed />
      
      <div className="section-header">
        <h2>My Settled Slips:</h2>
      </div>
      <KapperSettledFeed />

      {showScrollButton && (
        <button
          className="scroll-to-top-button"
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </div>
  );
}

export default KapperStats;
