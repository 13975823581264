import React, { useEffect, useState } from 'react';
import apiClient from '../../../../services/apiClient';
import './Subscriptions.css';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await apiClient.get('/subscription/subscriptions');
        setSubscriptions(response.data || []);
      } catch (err) {
        console.error('Error fetching subscriptions:', err.message);
        setError('Failed to fetch subscriptions. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleUnsubscribe = async (username) => {
    try {
      await apiClient.post('/subscription/unsubscribe', { username });
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.filter((subscription) => subscription.username !== username)
      );
    } catch (err) {
      console.error('Error unsubscribing:', err.message);
      alert('Failed to unsubscribe. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading your subscriptions...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="subscriptions-container">
      <div className="subscriptions-grid">
        {subscriptions.map((subscription) => (
          <div className="kapper-card" key={subscription.kapper_id}>
            <button
              className="unsubscribe-button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering other events
                handleUnsubscribe(subscription.username);
              }}
            >
              Unsubscribe
            </button>
              <div className="kapper-info">
                <img
                  src={`${STATIC_URL}/${subscription.profile_picture}`}
                  alt={`${subscription.username}'s profile`}
                  className="kapper-profile-picture"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/profile.png';
                  }}
                />
                <h5 className="kapper-username">{subscription.username}</h5>
              </div>
              <div className="kapper-leagues">
                {subscription.leagues && subscription.leagues.length > 0
                  ? subscription.leagues.map((league, index) => (
                      <span key={index} className="kapper-league">
                        {league}
                      </span>
                    ))
                  : <span className="no-leagues">No leagues selected</span>}
              </div>
            </div>
        ))}
      </div>
    </div>
  );
}

export default Subscriptions;
