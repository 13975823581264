import React, { useEffect, useState } from 'react';
import SubscriberFeed from '../../feeds/Subscribers/SubscriberFeed/SubscriberFeed';
import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page-container">
        { <SubscriberFeed />}
    </div>
  );
}

export default HomePage;
