import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import apiClient from '../../../services/apiClient';
import './kapperUnits.css';

function KapperUnits() {
  const [unitsHistory, setUnitsHistory] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [hoveredUnits, setHoveredUnits] = useState(null);
  const [hoveredChange, setHoveredChange] = useState(0);
  const [change, setChange] = useState(0);
  const [hovered, setHovered] = useState(false); // Tracks hover state
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnitsHistory = async () => {
      try {
        const response = await apiClient.get('/stats/kapperFeed/unitsHistory');
        const { success, data } = response.data;

        if (!success) {
          throw new Error('API returned failure');
        }

        const past30DaysData = filterPast30Days(data);
        calculateMetrics(data, past30DaysData);
        setUnitsHistory(interpolateDates(past30DaysData));
      } catch (err) {
        console.error('Error fetching units history:', err);
        setError(err.message || 'Unknown error');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUnitsHistory();
  }, []);

  const filterPast30Days = (data) => {
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - 30);

    return data.filter((entry) => new Date(entry.timestamp) >= cutoffDate);
  };

  const calculateMetrics = (allData, past30DaysData) => {
    if (allData.length > 0) {
      const lastEntry = parseFloat(allData[allData.length - 1].total_units);
      setTotalUnits(lastEntry);
    }

    if (past30DaysData.length > 1) {
      const first = parseFloat(past30DaysData[0].total_units);
      const last = parseFloat(past30DaysData[past30DaysData.length - 1].total_units);
      setChange(last - first);
    } else {
      setChange(0);
    }
  };

  const interpolateDates = (data) => {
    const result = [];
    if (data.length === 0) return result;

    let prevDate = new Date(data[0].timestamp);
    const endDate = new Date(data[data.length - 1].timestamp);

    let prevValue = parseFloat(data[0].total_units);

    for (let i = 0; i < data.length; i++) {
      const currentDate = new Date(data[i].timestamp);
      const currentValue = parseFloat(data[i].total_units);

      // Add interpolated points for missing dates
      while (prevDate < currentDate) {
        result.push({ timestamp: prevDate.toISOString(), total_units: prevValue });
        prevDate.setDate(prevDate.getDate() + 1);
      }

      result.push({ timestamp: currentDate.toISOString(), total_units: currentValue });
      prevDate = new Date(currentDate);
      prevValue = currentValue;
    }

    // Fill remaining dates up to the endDate
    while (prevDate <= endDate) {
      result.push({ timestamp: prevDate.toISOString(), total_units: prevValue });
      prevDate.setDate(prevDate.getDate() + 1);
    }

    return result;
  };

  const handleHover = (event) => {
    const { points } = event;
    if (points.length > 0) {
      const point = points[0];
      const hoveredValue = point.y; // Hovered units value
      const initialValue = unitsHistory.length > 0 ? parseFloat(unitsHistory[0].total_units) : 0;

      setHoveredUnits(hoveredValue);
      setHoveredChange(hoveredValue - initialValue); // Calculate change dynamically
      setHovered(true); // Set hover state
    }
  };

  const handleUnhover = () => {
    const mostRecentPoint = unitsHistory[unitsHistory.length - 1];
    if (mostRecentPoint) {
      setHoveredUnits(parseFloat(mostRecentPoint.total_units)); // Reset to the most recent point
      const initialValue = unitsHistory.length > 0 ? parseFloat(unitsHistory[0].total_units) : 0;
      setHoveredChange(parseFloat(mostRecentPoint.total_units) - initialValue); // Reset change dynamically
    }
    setHovered(false); // Reset hover state
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const timestamps = unitsHistory.map((entry) => entry.timestamp || '');
  const totalUnitsData = unitsHistory.map((entry) => parseFloat(entry.total_units) || 0);

  const minY = Math.min(...totalUnitsData);
  const maxY = Math.max(...totalUnitsData);
  const yAxisRange = [minY - 10, maxY + 10];

  return (
    <div>
      <div className="card kapper-units-card">
        <div className="kapper-units-header">
          <div className="kapper-units-header-content">
            <h1 className="kapper-units-total">
              {hoveredUnits !== null ? hoveredUnits.toFixed(2) : totalUnits.toFixed(2)} Total Units
            </h1>
            <h2
              className={`kapper-units-change ${
                hoveredChange >= 0 ? 'kapper-units-change-positive' : 'kapper-units-change-negative'
              }`}
            >
              {hovered
                ? `${hoveredChange >= 0 ? '+' : ''}${hoveredChange.toFixed(2)} Units`
                : `${change >= 0 ? '+' : ''}${change.toFixed(2)} Units / 30 Days`}
            </h2>
          </div>
        </div>
        <div className="kapper-units-graph">
<Plot
            data={[
  
    // Main graph line
    {
      x: timestamps,
      y: totalUnitsData,
      type: 'scatter',
      mode: 'lines',
      line: {
        shape: 'linear',
        width: 2,
        color: 'rgb(40, 202, 4)',
      },
    },
    // Undershading
    {
      x: timestamps,
      y: totalUnitsData,
      type: 'scatter',
      mode: 'none',
      fill: 'tozeroy', // Fills the area to the x-axis (zero)
      fillcolor: 'rgba(40, 202, 4, 0.2)', // Undershading color with opacity
      hoverinfo: 'skip', // Ignore hover for undershading
    },
  ]}
  layout={{
    xaxis: {
      visible: false,
      showgrid: false,
      fixedrange: true,
    },
    yaxis: {
      visible: false,
      range: yAxisRange,
      showgrid: false,
      fixedrange: true,
    },
    margin: { t: 10, l: 0, r: 0, b: 0 },
    paper_bgcolor: 'white',
    plot_bgcolor: 'white',
    hovermode: false,
    showlegend: false, // Hide the legend
  }}
  config={{
    displayModeBar: false,
    scrollZoom: false,
    staticPlot: true,
    responsive: true,
  }}
  style={{ width: '100%', height: '120px' }}
/>
        </div>
      </div>
    </div>
  );
}

export default KapperUnits;
