import React, { useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { formatDistanceToNow } from 'date-fns';
import ReactionBar from '../ReactionBar/ReactionBar';
import './KapperPostCard.css';

const STATIC_URL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

const calculateOddsBorderColor = (odds) => {
    const minOdds = -2000;
    const maxOdds = 2000;
    const yellow = [255, 255, 0];
    const red = [238, 0, 0];
    const green = [43, 176, 43];

    if (odds >= 0) {
        const ratio = odds / maxOdds;
        const color = yellow.map((component, index) =>
            Math.round(component + ratio * (red[index] - component))
        );
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
        const ratio = Math.abs(odds) / Math.abs(minOdds);
        const color = yellow.map((component, index) =>
            Math.round(component + ratio * (green[index] - component))
        );
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
};

const calculateUnitsGainedOrLost = (post) => {
    const units = parseFloat(post.units) || 0;

    if (post.is_hit === 1) {
        const winnings = post.odds > 0 
            ? units * (post.odds / 100)
            : units * (100 / Math.abs(post.odds));
        return {
            text: `+${winnings.toFixed(2)} ${winnings === 1 ? 'Unit' : 'Units'}`,
            className: 'units-gained',
        };
    } else if (post.is_hit === 0) {
        return {
            text: `-${units.toFixed(2)} ${units === 1 ? 'Unit' : 'Units'}`,
            className: 'units-lost',
        };
    }
    return null;
};

const KapperPostCard = ({ post, onUpdateHitStatus, onMarkAsSettled, swipeable, isDisappearing }) => {

    const tapTimeoutRef = useRef(null);
    const [visualHitState, setVisualHitState] = useState(post.is_hit); // Local visual state for hit/miss

const handleDoubleTap = async () => {
  console.log('Double tap detected');
  if (onMarkAsSettled) {
    try {
      const updatedHitState = visualHitState === 1 ? 0 : 1;
      console.log(`Updating visualHitState to: ${updatedHitState}`);
      setVisualHitState(updatedHitState);

      await onUpdateHitStatus(post.id, updatedHitState);
      console.log(`Successfully updated hit status for post ID: ${post.id}`);

      await onMarkAsSettled(post.id);
      console.log(`Successfully marked post as settled for post ID: ${post.id}`);
    } catch (error) {
      console.error('Error marking post as settled:', error.message);
      setVisualHitState(post.is_hit); // Revert on error
    }
  } else {
    console.warn('onMarkAsSettled function not provided.');
  }
};


const handleTap = () => {
  if (tapTimeoutRef.current) {
    clearTimeout(tapTimeoutRef.current);
    tapTimeoutRef.current = null;
    console.log('Double tap detected');
    handleDoubleTap();
  } else {
    tapTimeoutRef.current = setTimeout(() => {
      tapTimeoutRef.current = null;
      console.log('Single tap detected');
    }, 300);
  }
};



const swipeHandlers = useSwipeable({
    onSwipedLeft: () => swipeable && setVisualHitState(0),
    onSwipedRight: () => swipeable && setVisualHitState(1),
    preventScrollOnSwipe: true,
    trackMouse: true,
});


    const contentBorderClass = visualHitState === 1
        ? 'hit-border'
        : visualHitState === 0
        ? 'miss-border'
        : '';

    const unitsResult = calculateUnitsGainedOrLost({ ...post, is_hit: visualHitState });

    const betLegs = React.useMemo(() => {
        if (Array.isArray(post.bet_legs)) {
            return post.bet_legs; // Already parsed
        }
        if (typeof post.bet_legs === 'string') {
            return post.bet_legs.split(';').map((leg) => leg.trim()).filter(Boolean);
        }
        return [];
    }, [post.bet_legs]);

    return (
<div
  className={`feed-post-card ${post.is_settled === 0 ? 'active-border' : ''} ${
    isDisappearing ? 'disappearing' : ''
  }`}
  onClick={handleTap}
  {...swipeHandlers}
>


            <div className="feed-post-header">
                <span className="feed-post-date feed-post-timestamp-large">
                    {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
                </span>
                <div className="feed-post-units-league-container">
                    <span className="feed-post-league">{post.league_with_emoji}</span>
                    <span className="feed-post-units">
                        {post.units} {post.units === 1 ? 'Unit' : 'Units'}
                    </span>

                    {unitsResult && (
                        <span className={`feed-post-units ${unitsResult.className}`}>
                            {unitsResult.text.replace('Units', unitsResult.text.includes('1.00') ? 'Unit' : 'Units')}
                        </span>
                    )}
                </div>
            </div>

<div className="feed-post-content">
  <h5 className="feed-post-title">{post.title}</h5>
  {betLegs.length > 0 && (
    <ul className="feed-bet-legs">
      {betLegs.map((leg, index) => (
        <li key={index}>{leg}</li>
      ))}
    </ul>
  )}

  {post.image && (
    <img
      src={`${STATIC_URL}/${post.image}`}
      alt="Post Image"
      className={`feed-post-image ${contentBorderClass}`} // Move contentBorderClass here
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = '/default-image.png';
      }}
    />
  )}
</div>
            {post.analysis && (
                <p className="feed-post-analysis">*{post.analysis}</p>
            )}
            <div
                className="feed-post-odds"
                style={{ borderBottom: `0px solid ${calculateOddsBorderColor(post.odds)}` }}
            >
                {post.odds === 100 ? 'Even' : post.odds > 0 ? `+${post.odds}` : post.odds}
            </div>

            <ReactionBar
                totalPlays={Number(post.total_plays)}
                totalPasses={Number(post.total_passes)}
                onReact={(isPlay) => {
                    console.log(`Reacted ${isPlay ? 'Play' : 'Pass'} on KapperPostCard`);
                }}
            />
        </div>
    );
};

export default KapperPostCard;
