import React, { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import './SettledFeed.css';

function SettledFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null); // Selected league filter
  const [hitFilter, setHitFilter] = useState(null); // Hit/Miss filter
  const [playFilter, setPlayFilter] = useState(null); // Play/Pass/None filter
  const [sortOrder, setSortOrder] = useState('desc'); // Timeline sorting order

  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const { data } = await apiClient.get('/stats/users/bankroll');
        setBankroll(data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
        setBankroll(null); // Default to null if bankroll fetch fails
      }
    };

    const fetchSettledFeed = async () => {
      try {
        const response = await apiClient.get('/feed/settledFeed');
        setPosts(response.data);
      } catch (err) {
        console.error('Error fetching settled feed:', err.message);
        setError('Failed to load settled plays. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBankroll();
    fetchSettledFeed();
  }, []);

  const applyFilters = () => {
    let filteredPosts = [...posts];

    // Filter by league
    if (selectedLeague) {
      filteredPosts = filteredPosts.filter(
        (post) => post.league_with_emoji === selectedLeague
      );
    }

    // Filter by Hit/Miss
    if (hitFilter === 'hit') {
      filteredPosts = filteredPosts.filter((post) => post.is_hit === 1);
    } else if (hitFilter === 'miss') {
      filteredPosts = filteredPosts.filter((post) => post.is_hit === 0);
    }

    // Filter by Play/Pass
    if (playFilter === 'play') {
      filteredPosts = filteredPosts.filter((post) => post.user_reaction === true);
    } else if (playFilter === 'pass') {
      filteredPosts = filteredPosts.filter((post) => post.user_reaction === false);
    } else if (playFilter === 'none') {
      filteredPosts = filteredPosts.filter((post) => post.user_reaction === null);
    }

    // Sort by timeline
    filteredPosts.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    return filteredPosts;
  };

  const groupPostsByDate = () => {
    const filteredPosts = applyFilters();

    return filteredPosts.reduce((acc, post) => {
      const postDate = new Date(post.created_at);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      let groupLabel;

      if (postDate.toDateString() === today.toDateString()) {
        groupLabel = 'Today';
      } else if (postDate.toDateString() === yesterday.toDateString()) {
        groupLabel = 'Yesterday';
      } else {
        groupLabel = postDate.toLocaleDateString();
      }

      if (!acc[groupLabel]) acc[groupLabel] = [];
      acc[groupLabel].push(post);
      return acc;
    }, {});
  };

  const renderPostGroups = () => {
    const groupedPosts = Object.entries(groupPostsByDate());

    return groupedPosts.map(([label, posts]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {posts.map((post) => (
          <FeedPostCard key={post.id} post={post} bankroll={bankroll} />
        ))}
      </div>
    ));
  };

  const uniqueLeagues = [...new Set(posts.map((post) => post.league_with_emoji))];

  if (loading) {
    return <div className="feed-loading">Loading your settled plays...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (posts.length === 0) {
    return <div className="feed-empty">Waiting for your first slip to be settled.</div>;
  }

  return (
    <div className="feed-container">
      <div className="feed-header">
        <button
          className="filter-sort-button"
          onClick={() => setIsModalOpen(true)}
        >
          Filter & Sort
        </button>
      </div>
      {renderPostGroups()}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <button className="close-icon" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <div className="modal-content">
              <h3><strong>Filter/Sort Options</strong></h3>
              <div className="filter-box">
                <div className="filter-section">
                  <h4>League</h4>
                  <div className="filter-options">
                    {uniqueLeagues.map((league) => (
                      <button
                        key={league}
                        className={`filter-button ${
                          selectedLeague === league ? 'active' : ''
                        }`}
                        onClick={() =>
                          setSelectedLeague(selectedLeague === league ? null : league)
                        }
                      >
                        {league}
                      </button>
                    ))}
                  </div>
                </div>
                          <hr className="divider-line" />
                <div className="filter-section">
                  <h4>Result</h4>
                  <div className="filter-options">
                    <button
                      className={`filter-button ${hitFilter === 'hit' ? 'active' : ''}`}
                      onClick={() => setHitFilter(hitFilter === 'hit' ? null : 'hit')}
                    >
                      Hit
                    </button>
                    <button
                      className={`filter-button ${hitFilter === 'miss' ? 'active' : ''}`}
                      onClick={() => setHitFilter(hitFilter === 'miss' ? null : 'miss')}
                    >
                      Miss
                    </button>
                  </div>
                </div>
                          <hr className="divider-line" />
                <div className="filter-section">
                  <h4>Reaction</h4>
                  <div className="filter-options">
                    <button
                      className={`filter-button ${playFilter === 'play' ? 'active' : ''}`}
                      onClick={() => setPlayFilter(playFilter === 'play' ? null : 'play')}
                    >
                      Play
                    </button>
                    <button
                      className={`filter-button ${playFilter === 'pass' ? 'active' : ''}`}
                      onClick={() => setPlayFilter(playFilter === 'pass' ? null : 'pass')}
                    >
                      Pass
                    </button>
                    <button
                      className={`filter-button ${playFilter === 'none' ? 'active' : ''}`}
                      onClick={() => setPlayFilter(playFilter === 'none' ? null : 'none')}
                    >
                      None
                    </button>
                  </div>
                </div>
                          <hr className="divider-line" />
                <div className="filter-section">
                  <h4>Sort By</h4>
                  <div className="filter-options">
                    <button
                      className={`filter-button ${sortOrder === 'asc' ? 'active' : ''}`}
                      onClick={() => setSortOrder('asc')}
                    >
                      Oldest First
                    </button>
                    <button
                      className={`filter-button ${sortOrder === 'desc' ? 'active' : ''}`}
                      onClick={() => setSortOrder('desc')}
                    >
                      Newest First
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="form-submit-button"
                onClick={() => setIsModalOpen(false)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettledFeed;
