import React, { useState, useEffect } from 'react';
import apiClient from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faTimes, faMessage } from '@fortawesome/free-solid-svg-icons';
import './CreateAlert.css';

function CreateAlert({ refreshFeed }) {
  const [isPublic, setIsPublic] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [message, setMessage] = useState('');
  const [kapperId, setKapperId] = useState(null);
  const [error, setError] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [activeTab, setActiveTab] = useState('text'); // 'text' or 'image'
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await apiClient.get('/users/profile');
        const userData = response.data;

        if (!userData.kapper_id) {
          alert('You must be a kapper to create an alert.');
          navigate('/');
          return;
        }

        setKapperId(userData.kapper_id);
      } catch (err) {
        console.error('Error fetching profile:', err.response?.data || err.message);
        alert('Error fetching profile. Please try again later.');
        navigate('/');
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (isSubmitting) return; // Prevent duplicate submissions
    setIsSubmitting(true);

    if (!kapperId) {
      alert('Kapper ID not found. Please log in again.');
      setIsSubmitting(false);
      return;
    }

    if (!title && !body && !image) {
      setError('You must provide a title, body, or upload an image.');
      setIsSubmitting(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('kapper_id', kapperId);
      formData.append('title', title);
      formData.append('body', body);
      formData.append('is_public', isPublic);

      if (image) {
        formData.append('image', image); // Include image if provided
      }

      const response = await apiClient.post('/alerts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage(response.data.message);
      setTitle('');
      setBody('');
      setIsPublic(false);
      setImage(null);
      setPreview(null);
      setIsFormVisible(false); // Hide the form after a successful submission

      if (refreshFeed) {
        refreshFeed(); // Trigger feed refresh
      }
    } catch (err) {
      console.error('Error creating alert:', err.response?.data || err.message);
      setError('Failed to create alert. Please try again.');
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="create-alert-container">
      <button
        className="create-alert-open-button"
        onClick={() => setIsFormVisible(!isFormVisible)}
      ><span className="create-alert-button-text">New Update</span>
      </button>

      {isFormVisible && (
        <div className="create-alert-form">
          {message && <p className="alert-info">{message}</p>}
          {error && <p className="alert-danger">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="section">
              <input
                type="text"
                id="title"
                value={title}
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="create-alert-tab-menu">
              <div
                className={`create-alert-tab ${activeTab === 'text' ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab('text');
                  setImage(null);
                  setPreview(null);
                }}
              >
                Text
              </div>
              <div
                className={`create-alert-tab ${activeTab === 'image' ? 'active' : ''}`}
                onClick={() => {
                  setActiveTab('image');
                  setBody('');
                }}
              >
                Image
              </div>
            </div>

            {activeTab === 'text' && (
              <div className="section">
                <textarea
                  id="body"
                  placeholder="Body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  className="form-control"
                ></textarea>
              </div>
            )}

            {activeTab === 'image' && (
              <div className="upload-image-container">
                {preview ? (
                  <div className="image-preview">
                    <img src={preview} alt="Preview" className="uploaded-image" />
                    <button
                      type="button"
                      className="clear-image-button"
                      onClick={() => {
                        setPreview(null);
                        setImage(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ) : (
                  <label htmlFor="alert-image" className="upload-label">
                    <div className="upload-icon">
                      <FontAwesomeIcon icon={faArrowUpFromBracket} size="3x" />
                    </div>
                    <p className="upload-text">Tap to upload an image</p>
                  </label>
                )}
                <input
                  type="file"
                  id="alert-image"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setImage(file);
                    setPreview(file ? URL.createObjectURL(file) : null);
                  }}
                  className="upload-input"
                />
              </div>
            )}

            <button
              type="submit"
              className="create-alert-submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Posting...' : 'Post Alert'}
            </button>
            <div className="disclaimer">*All unpinned messages expire after 24hrs</div>
          </form>
        </div>
      )}
    </div>
  );
}

export default CreateAlert;
