import React, { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import KapperPostCard from '../../../components/cards/KapperPostCard/KapperPostCard';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard'; // For tailed feed items
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';
import './KapperFeed.css';

function KapperFeed() {
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);
  const [disappearingPosts, setDisappearingPosts] = useState([]); // Track disappearing posts
const handleMarkAsSettled = async (postId) => {
  try {
    setDisappearingPosts((prev) => [...prev, postId]); // Mark as disappearing
    setTimeout(async () => {
      await apiClient.post('/reactions/markAsSettled', { post_id: postId });
      setFeedItems((prevItems) => prevItems.filter((item) => item.id !== postId));
      setDisappearingPosts((prev) => prev.filter((id) => id !== postId)); // Remove from disappearing
    }, 300); // Match CSS animation duration
  } catch (error) {
    console.error('Error marking post as settled:', error.response?.data || error.message);
  }
};


const handleUpdateHitStatus = async (postId, isHit) => {
  try {
    await apiClient.post('/reactions/updateHit', { post_id: postId, is_hit: isHit });
    console.log(`Hit status updated for post ID: ${postId}`);
  } catch (error) {
    console.error('Error updating hit status:', error.response?.data || error.message);
  }
};


  const fetchFeedData = async () => {
    try {
      setLoading(true);

      // Fetch Kapper Feed
      const kapperResponse = await apiClient.get('/feed/kapperFeed');
      const enrichedKapperItems = kapperResponse.data
        .filter((item) => item.type !== 'alert') // Remove alerts from the feed
        .map((item) => {
          if (item.type === 'post') {
            return {
              ...item,
              bet_legs: Array.isArray(item.bet_legs)
                ? item.bet_legs
                : typeof item.bet_legs === 'string'
                ? item.bet_legs.split(';').map((leg) => leg.trim()).filter(Boolean)
                : [],
            };
          }
          return item;
        });

      // Fetch Tailed Feed
      const tailedResponse = await apiClient.get('/feed/tailed-feed');
      const enrichedTailedItems = tailedResponse.data.posts.map((item) => ({
        ...item,
        type: 'tailed', // Mark as tailed post
        bet_legs: Array.isArray(item.bet_legs)
          ? item.bet_legs
          : typeof item.bet_legs === 'string'
          ? item.bet_legs.split(';').map((leg) => leg.trim()).filter(Boolean)
          : [],
        total_plays: Number(item.total_plays),
        user_reaction: item.user_reaction !== null ? Number(item.user_reaction) : 0,
      }));

      // Combine and sort all items by date
      const combinedFeed = [...enrichedKapperItems, ...enrichedTailedItems].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      setFeedItems(combinedFeed);
      setError(null);
    } catch (err) {
      console.error('Error fetching feed data:', err.response?.data || err.message);
      setError('Failed to load feed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const { data } = await apiClient.get('/stats/users/bankroll');
        setBankroll(data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
        setBankroll(null);
      }
    };

    fetchBankroll();
    fetchFeedData();
  }, []);

  const renderFeedItems = () => {
    const groupedItems = feedItems.reduce((acc, item) => {
      const itemDate = new Date(item.created_at);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      let groupLabel;

      if (itemDate.toDateString() === today.toDateString()) {
        groupLabel = 'Today';
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        groupLabel = 'Yesterday';
      } else {
        groupLabel = itemDate.toLocaleDateString();
      }

      if (!acc[groupLabel]) acc[groupLabel] = [];
      acc[groupLabel].push(item);
      return acc;
    }, {});

    return Object.entries(groupedItems).map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((item) =>
          item.type === 'post' ? (
<KapperPostCard
  key={item.id}
  post={item}
  swipeable={true}
  onMarkAsSettled={handleMarkAsSettled}
  onUpdateHitStatus={handleUpdateHitStatus}
  isDisappearing={disappearingPosts.includes(item.id)} // Pass disappearing state
/>
          ) : (
<FeedPostCard
  key={item.post_id}
  post={item}
  bankroll={bankroll}
  disablePlay={true} // Disable play/unplay on this feed
/>
          )
        )}
      </div>
    ));
  };

  if (loading) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (feedItems.length === 0) {
    return (
      <div className="feed-container">
        <div className="feed-empty">
          <FontAwesomeIcon icon={faBed} className="bed-icon" /> No active slips.
        </div>
      </div>
    );
  }

  return <div className="feed-container">{renderFeedItems()}</div>;
}

export default KapperFeed;
