import React, { useEffect, useState } from 'react';
import CreateAlert from '../../components/post/CreateAlert/CreateAlert';
import CreatePost from '../../components/post/CreatePost/CreatePost';
import './PostPage.css'

function PostPage() {
  return (
      <div className="post-page-container">
          { <CreatePost />}
        { <CreateAlert />}
    </div>
  );
}

export default PostPage;
