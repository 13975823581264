import React, { memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import navigation hook
import apiClient from '../../../services/apiClient';
import { formatDistanceToNow } from 'date-fns';
import ReactionBar from '../ReactionBar/ReactionBar';
import './FeedPostCard.css';


const STATIC_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_STATIC_BASE_URL
  : 'http://localhost:5000';

const calculateOddsBorderColor = (odds) => {
  const minOdds = -2000;
  const maxOdds = 2000;
  const yellow = [255, 255, 0];
  const red = [238, 0, 0];
  const green = [43, 176, 43];

  if (odds >= 0) {
    const ratio = odds / maxOdds;
    const color = yellow.map((component, index) =>
      Math.round(component + ratio * (red[index] - component))
    );
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  } else {
    const ratio = Math.abs(odds) / Math.abs(minOdds);
    const color = yellow.map((component, index) =>
      Math.round(component + ratio * (green[index] - component))
    );
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }
};


const calculateUnitsGainedOrLost = (post, bankroll) => {
  if (!post.is_settled) return null;

  const units = parseFloat(post.units) || 0; // Safely parse units as a number
  const unitValue = (parseFloat(bankroll) || 0) * (post.conversion_rate || 0);

  if (post.is_hit === 1) {
    const winnings = post.odds > 0
      ? units * (post.odds / 100)
      : units * (100 / Math.abs(post.odds));
    return bankroll !== null && post.conversion_rate > 0
      ? `+$${(winnings * unitValue).toFixed(2)}`
      : `+${winnings.toFixed(2)} ${winnings === 1 ? 'Unit' : 'Units'}`;

  } else if (post.is_hit === 0) {
    const loss = units;
    return bankroll !== null && post.conversion_rate > 0
      ? `-$${(loss * unitValue).toFixed(2)}`
      : `-${loss.toFixed(2)} ${loss === 1 ? 'Unit' : 'Units'}`;
  }
  return null;
};

const FeedPostCard = memo(({ post, updateFeed = () => {}, bankroll, disablePlay = false }) => {

  const profilePicture = React.useMemo(() => {
    return post.profile_picture ? `${STATIC_URL}/${post.profile_picture}` : '/profile.png';
  }, [post.profile_picture]);

  const navigate = useNavigate(); // Use React Router for navigation

  const handleProfileClick = () => {
    navigate(`/${post.kapper_display_name}`); // Navigate to Kapper's profile page
  };

  // Ref for handling double-tap
  const tapTimeoutRef = useRef(null);

  // Handles double-tap for toggling `is_play`
  const handleTap = () => {
    if (tapTimeoutRef.current) {
      clearTimeout(tapTimeoutRef.current);
      tapTimeoutRef.current = null;
      handleDoubleTapReaction(); // Trigger double-tap action
    } else {
      tapTimeoutRef.current = setTimeout(() => {
        tapTimeoutRef.current = null;
      }, 300); // Single-tap delay
    }
  };

const handleDoubleTapReaction = async () => {
  if (disablePlay) return; // Do nothing if play functionality is disabled

  const isCurrentlyReacted = post.user_reaction === 1;
  const updatedTotalPlays = isCurrentlyReacted
    ? Math.max(0, post.total_plays - 1) // Ensure total_plays doesn't go below 0
    : post.total_plays + 1; // Increment total_plays when reacting

  const newPlayState = isCurrentlyReacted ? 0 : 1; // Toggle reaction

  // Optimistically update the frontend state
  updateFeed(post.post_id, newPlayState, updatedTotalPlays);

  try {
    // Persist the reaction in the backend
    await apiClient.post('/reactions/react', { post_id: post.post_id, is_play: newPlayState });
  } catch (error) {
    console.error('Error handling reaction:', error.response?.data || error.message);

    // Revert state if backend call fails
    const revertedTotalPlays = isCurrentlyReacted
      ? post.total_plays // Restore to original
      : Math.max(0, post.total_plays - 1); // Restore for failed react
    updateFeed(post.post_id, post.user_reaction, revertedTotalPlays);
  }
};


  const displayValue =
    bankroll !== null && post.conversion_rate > 0
      ? `$${(bankroll * post.conversion_rate * (parseFloat(post.units) || 0)).toFixed(2)}`
      : `${parseFloat(post.units) || 0} ${parseFloat(post.units) === 1 ? 'Unit' : 'Units'}`; // Handle singular/plural


  const contentBorderClass =
    post.is_settled === 1
      ? post.is_hit === 1
        ? 'hit-border'
        : post.is_hit === 0
        ? 'miss-border'
        : ''
      : '';

  const reactionBorderClass = post.user_reaction === true
    ? 'play-border'
    : post.user_reaction === false
    ? 'pass-border'
    : '';

  const unitsResult = calculateUnitsGainedOrLost(post, bankroll);

  // Ensure bet legs are properly parsed as an array
  const betLegs = post.bet_legs || [];
 
  return (
<div
  className={`feed-post-card`}   onClick={handleTap}
>

      <div className="feed-post-header">
        <div className="feed-post-header-left">
          <button
            className="profile-button"
            onClick={handleProfileClick}
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <img
              src={profilePicture}
              alt={`${post.kapper_display_name}'s profile`}
              className="feed-profile-picture"
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = '/profile.png'; // Fallback to default profile picture
              }}
            />
          </button>
          <div className="feed-post-header-right">
            <h5 className="feed-post-kapper">{post.kapper_display_name}</h5>
            <span className="feed-post-date">
              {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
            </span>
          </div>
        </div>
        <div className="feed-post-units-league-container">
          <span className="feed-post-league">{post.league_with_emoji}</span>
          <span className="feed-post-units">{displayValue}</span>
          {unitsResult && (
            <span
              className={`feed-post-units ${
                unitsResult.startsWith('+') ? 'units-gained' : 'units-lost'
              }`}
            >
              {unitsResult}
            </span>
          )}
        </div>
      </div>

      <div className={`feed-post-content}`}>
        <h5 className="feed-post-title">{post.title}</h5>
        {betLegs.length > 0 && (
          <ul className="feed-bet-legs">
            {betLegs.map((leg, index) => (
              <li key={index}>{leg}</li>
            ))}
          </ul>
        )}
        {post.image && (
<img
  src={`${STATIC_URL}/${post.image}`}
  alt="Post Image"
  className={`feed-post-image ${
    post.is_settled === 0 && post.user_reaction === 1
      ? 'play-border'
      : post.is_settled === 1
      ? contentBorderClass
      : ''
  }`}
  onError={(e) => {
    e.target.onerror = null;
    e.target.src = '/default-image.png';
  }}
/>
        )}
      </div>
      {post.analysis && (
        <p className="feed-post-analysis">*{post.analysis}</p>
      )}
      <div
        className="feed-post-odds"
        style={{
          borderBottom: `0px solid ${calculateOddsBorderColor(post.odds)}`,
        }}
      >
        {post.odds === 100 ? 'Even' : post.odds > 0 ? `+${post.odds}` : post.odds}
      </div>

<ReactionBar
  totalPlays={Number(post.total_plays)}
  onReact={handleDoubleTapReaction}
  userReacted={post.user_reaction === 1} // Check if the user has reacted
/>

    </div>
  );
});

export default FeedPostCard;
