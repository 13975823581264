import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css';

const ErrorPage = ({ message = "Oops! The page you're looking for doesn't exist." }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleGoHome = () => {
    navigate('/'); // Navigate to the homepage
  };

  return (
    <div className="error-page">
      <h1 className="error-title">ERROR 404</h1>
      <p className="error-message">{message}</p>
      <div className="error-actions">
        <button className="error-button" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="error-button" onClick={handleGoHome}>
          Go Home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
