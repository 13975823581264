import React, { useEffect, useState, useCallback } from 'react';
import apiClient from '../../services/apiClient';
import KapperAlertCard from '../../components/cards/KapperAlertCard/KapperAlertCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

function KapperAlertsFeed() {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAlerts = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get('/feed/kapperUpdates'); // New alerts-only endpoint
      setAlerts(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching alerts:', err.response?.data || err.message);
      setError('Failed to load alerts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const handleDeleteAlert = async (alertId) => {
    try {
      await apiClient.post('/alerts/delete', { alert_id: alertId });
      setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.alert_id !== alertId));
    } catch (err) {
      console.error('Error deleting alert:', err.response?.data || err.message);
      alert('Failed to delete the alert. Please try again.');
    }
  };

  const handlePinAlert = (alertId, isPinned) => {
    setAlerts((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert.alert_id === alertId ? { ...alert, is_pinned: isPinned } : alert
      )
    );
  };

  const renderAlerts = () => {
    return alerts.map((alert) => (
      <KapperAlertCard
        key={alert.alert_id}
        alert={alert}
        onDelete={handleDeleteAlert}
        onPin={handlePinAlert}
      />
    ));
  };

  if (loading) {
    return <div className="alerts-loading">Loading...</div>;
  }

  if (error) {
    return <div className="alerts-error">{error}</div>;
  }

  if (alerts.length === 0) {
    return (
      <div className="alerts-container">
        <div className="alerts-empty">
        </div>
      </div>
    );
  }

  return <div className="alerts-container">      <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">Active Updates</span>
        <hr className="divider-line" />
      </div>{renderAlerts()}</div>;
}

export default KapperAlertsFeed;
