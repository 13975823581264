import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import './RecentWinFeed.css';

function RecentWinFeed() {
  const { username } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0); // Current page (offset)
  const [showAll, setShowAll] = useState(false);

  // Fetch posts with limit and offset
  const fetchPosts = async (limit, offset) => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/kappers/${username}/recentWinFeed`, {
        params: { limit, offset },
      });

      const enrichedPosts = response.data.map((post) => ({
        ...post,
        bet_legs: post.bet_legs ? post.bet_legs.split(';') : [],
      }));

      setPosts((prevPosts) => [...prevPosts, ...enrichedPosts]);
      setHasMore(enrichedPosts.length === limit); // Determine if there are more posts
    } catch (err) {
      console.error('Error fetching posts:', err.message);
      setError('Failed to load recent wins. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Handle the "Show All" button
  const handleShowAll = () => {
    setShowAll(true);
    setPage(1); // Start loading additional posts incrementally
  };

  // Incrementally load posts as the user scrolls
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore && showAll) {
        setPage((prevPage) => prevPage + 1); // Increment the page number
      }
    }
  };

  // Initial fetch for 5 posts
  useEffect(() => {
    fetchPosts(5, 0); // Load the first 5 posts
  }, [username]);

  // Fetch one post at a time when scrolling
  useEffect(() => {
    if (showAll && page > 0) {
      fetchPosts(1, 5 + page - 1); // Start loading posts from offset 5
    }
  }, [page]);

  // Fetch bankroll
  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const response = await apiClient.get('/stats/users/bankroll');
        setBankroll(response.data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
      }
    };

    fetchBankroll();
  }, []);

  // Attach and detach scroll event listener
  useEffect(() => {
    if (showAll) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loading, hasMore, showAll]);

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (loading && posts.length === 0) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (posts.length === 0) {
    return <div className="feed-empty">No settled slips yet.</div>;
  }

  return (
    <div className="recent-win-feed">
      {posts.map((post) => (
        <FeedPostCard
          key={post.post_id}
          post={{
            ...post,
            image: post.image,
          }}
          bankroll={bankroll}
        />
      ))}
      {!showAll && (
        <button className="show-all-button" onClick={handleShowAll}>
          Show All
        </button>
      )}
      {loading && hasMore && showAll && <div className="feed-loading">Loading more...</div>}
    </div>
  );
}

export default RecentWinFeed;
