import React from 'react';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <h1 className="landing-header">Landing Page</h1>
      <p className="landing-subheader">
        (In Progress)
      </p>
    </div>
  );
}

export default LandingPage;
