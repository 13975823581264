import React, { useState, useEffect } from 'react';
import SettledFeed from '../../feeds/Subscribers/SettledFeed/SettledFeed';
import './SubscriberStats.css'; // Add a CSS file for styling

function SubscriberStats() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="settled-container">
      <SettledFeed />
      {showScrollButton && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          ↑
        </button>
      )}
    </div>
  );
}

export default SubscriberStats;
