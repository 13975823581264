import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../services/apiClient';
import './Kappers.css';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function Kappers() {
  const [kappers, setKappers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLeague, setSelectedLeague] = useState(null); // Default to no filter
  const navigate = useNavigate();

  useEffect(() => {
    apiClient
      .get('/kappers')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setKappers(response.data);
        } else {
          console.error('Unexpected API response:', response.data);
          setKappers([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching kappers:', error.response?.data || error.message);
        setKappers([]);
      });
  }, []);

  const handleCardClick = (username) => {
    navigate(`/${username}`);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLeagueFilter = (league) => {
    // Toggle league filter
    setSelectedLeague((prevLeague) => (prevLeague === league ? null : league));
  };

  const filteredKappers = Array.isArray(kappers)
    ? kappers.filter((kapper) => {
        const matchesSearch = kapper.username
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesLeague =
          !selectedLeague ||
          (kapper.leagues && kapper.leagues.includes(selectedLeague));
        return matchesSearch && matchesLeague;
      })
    : [];

  const uniqueLeagues = Array.isArray(kappers)
    ? [...new Set(kappers.flatMap((kapper) => kapper.leagues || []))]
    : [];

  return (
    <div className="kappers-container">
      <div className="search-bar-container">
        <div className="search-bar-wrapper">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
          <input
            type="text"
            placeholder="Find Kappers..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-bar"
          />
        </div>
      </div>
      <div className="league-filters">
        {uniqueLeagues.map((league) => (
          <button
            key={league}
            className={`league-filter-button ${
              selectedLeague === league ? 'active' : ''
            }`}
            onClick={() => handleLeagueFilter(league)}
          >
            {league}
          </button>
        ))}
      </div>
      <hr className="universal-divider-line" />
      <div className="kappers-grid">
        {filteredKappers.length > 0 ? (
          filteredKappers.map((kapper) => (
<div
  className={`kapper-card ${kapper.role === 'pro' ? 'pro-card' : ''}`}
  key={kapper.kapper_id}
  onClick={() => handleCardClick(kapper.username)}
>
              <div className="kapper-info">
                <img
                  src={`${STATIC_URL}/${kapper.profile_picture}`}
                  alt={`${kapper.username}'s profile`}
                  className="kapper-profile-picture"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/profile.png';
                  }}
                />
                <h5 className="kapper-display-name">{kapper.display_name} {kapper.role === 'pro' && (
                    <FontAwesomeIcon icon={faCheckCircle} className="badge-icon-small" />
                  )}</h5>
              </div>
              <div className="kapper-leagues">
                {kapper.leagues && kapper.leagues.length > 0
                  ? kapper.leagues.map((league, index) => (
                      <span key={index} className="kapper-league">
                        {league}
                      </span>
                    ))
                  : <span className="no-leagues"></span>}
              </div>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default Kappers;
