import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import LoginRegisterModal from '../login/LoginRegisterModal/LoginRegisterModal';
import apiClient from '../../services/apiClient';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faMagnifyingGlass,
  faGear,
  faBars,
  faTimes,
  faRightFromBracket,
  faUser,
  faChartLine,
  faCheckToSlot,
  faQuestion,
  faPlusSquare,
  faUserTie,
  faPiggyBank,
} from '@fortawesome/free-solid-svg-icons';
import {
faMoneyBill1,
    } from '@fortawesome/free-regular-svg-icons';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');
  const isLandingPage = location.pathname === '/';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('login');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
        try {
          const response = await apiClient.get('/users/profile');
          const fullPath =
            response.data.profile_picture &&
            `${
              process.env.NODE_ENV === 'production'
                ? process.env.REACT_APP_STATIC_BASE_URL
                : 'http://localhost:5000'
            }/${response.data.profile_picture}`;
          setProfilePicture(fullPath || '/profile.png');
        } catch (error) {
          console.error('Error fetching profile picture:', error);
          setProfilePicture('/profile.png');
        }
    };

    fetchProfilePicture();
  }, [isLoggedIn, role]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/');
    setIsSidebarOpen(false);
  };

  const openModal = (mode) => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setIsSidebarOpen(true),
    onSwipedRight: () => setIsSidebarOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  const [bankroll, setBankroll] = useState(null); // Add bankroll state

useEffect(() => {
  const fetchBankroll = async () => {
    try {
      const response = await apiClient.get('/stats/users/bankroll');
      if (response.data.success) {
        setBankroll(response.data.bankroll);
      } else {
        setBankroll(null);
      }
    } catch (error) {
      console.error('Error fetching bankroll:', error);
      setBankroll(null);
    }
  };

  fetchBankroll();
}, [isLoggedIn]); // Fetch bankroll when the user logs in


return (
  <>
    {/* Top Navbar */}


<nav
  className={`navbar ${!isLoggedIn ? 'logged-out' : ''} ${
    isLoggedIn && isScrolled ? 'navbar-scrolled' : ''
  }`}
    >
      
  <div className="navbar-left">
    <NavLink to="/" className="navbar-logo">
      <img
        src={isScrolled ? 'k.png' : 'Kapper.png'}
        alt="Kapper Logo"
        className="navbar-logo-img"
      />
    </NavLink>
  </div>
  <ul className="nav-links">
    {isLoggedIn ? (
      <>
        {/* Icons to hide on mobile */}
        <li className="nav-icon-item">
          <NavLink to="/home" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FontAwesomeIcon
              icon={faHouse}
              className="nav-icon"
            /> Home
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/discover" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="nav-icon"
            /> Discover
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/post" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FontAwesomeIcon
              icon={faPlusSquare}
              className="nav-icon"
            /> Post
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/kapperstats" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FontAwesomeIcon
              icon={faCheckToSlot}
              className="nav-icon"
            /> MySlips
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : '')}>
            {profilePicture ? (
              <img
                src={profilePicture || '/profile.png'}
                alt="Profile"
                className="nav-profile-picture"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/profile.png';
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faUser}
                className="nav-icon"
              />
            )}
          </NavLink>
        </li>
        {/* Sidebar Toggle */}
        <li
          className={`nav-item sidebar-toggle ${isSidebarOpen ? 'active' : ''}`}
          onClick={toggleSidebar}
        >
          <button type="button" className="sidebar-toggle-button">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faTimes : faBars}
              className="nav-icon"
            />
          </button>
        </li>
      </>
    ) : (
      <>
        {/* Login and Signup buttons for logged-out users */}
        <li>
          <NavLink
            to="#"
            className="nav-login-link"
            onClick={() => openModal('login')}
          >
            Login
          </NavLink>
        </li>
        <li>
          <button
            className="nav-signup-button"
            onClick={() => openModal('register')}
          >
            Sign Up
          </button>
        </li>
      </>
    )}
  </ul>
</nav>


    {/* Sidebar */}
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <ul className="sidebar-menu">
        {role === 'pro' && (
          <li>
            <Link to="/analytics" onClick={toggleSidebar} className="sidebar-item">
              <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" />
              <span className="sidebar-text">Analytics</span>
            </Link>
          </li>
        )}
        <li>
          <Link to="/account" onClick={toggleSidebar} className="sidebar-item">
            <FontAwesomeIcon icon={faGear} className="sidebar-icon" />
            <span className="sidebar-text">Subscriptions</span>
          </Link>
        </li>
        <li>
          <Link to="/help" onClick={toggleSidebar} className="sidebar-item">
            <FontAwesomeIcon icon={faQuestion} className="sidebar-icon" />
            <span className="sidebar-text">Help</span>
          </Link>
        </li>
      </ul>
      <div className="sidebar-logout">
        <Link className="logout-link" onClick={handleLogout}>
          <FontAwesomeIcon icon={faRightFromBracket} className="logout-icon" />
          Logout
        </Link>
      </div>
    </div>

    {/* Sidebar Overlay */}
    {isSidebarOpen && (
      <div
        className="sidebar-overlay"
        onClick={() => setIsSidebarOpen(false)}
      ></div>
    )}

    {/* Bottom Navbar */}
    {isLoggedIn && (
      <div className="bottom-navbar">
        <Link to="/home">
          <FontAwesomeIcon
            icon={faHouse}
            className={`nav-icon ${
              location.pathname === '/home' ? 'active' : ''
            }`}
          />
        </Link>
        <Link to="/discover">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={`nav-icon ${
              location.pathname === '/discover' ? 'active' : ''
            }`}
          />
        </Link>
                <Link to="/post">
          <FontAwesomeIcon
            icon={faPlusSquare}
            className={`nav-icon ${
              location.pathname === '/post' ? 'active' : ''
            }`}
          />
        </Link>
        <Link to="/kapperstats">
          <FontAwesomeIcon
            icon={faCheckToSlot}
            className={`nav-icon ${
              location.pathname === '/kapperstats' ? 'active' : ''
            }`}
          />
        </Link>
<Link to="/profile">
  <FontAwesomeIcon
    icon={role === 'pro' ? faUserTie : faUser}
    className={`nav-icon ${
      location.pathname === '/profile' ? 'active' : ''
    }`}
  />
</Link>

      </div>
    )}

    {/* Login/Register Modal */}
    {isModalOpen && (
      <LoginRegisterModal mode={modalMode} closeModal={closeModal} />
    )}
  </>
);



}

export default Navbar;
