import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/HomePage/HomePage';
import ProfilePage from './pages/AccountPages/ProfilePage/ProfilePage';
import Discover from './pages/DiscoverPage/DiscoverPage';
import KapperPage from './pages/UsernamePage/KapperPage';
import AccountPage from './pages/AccountPages/AccountPage/AccountPage';
import KapperStats from './pages/KapperSettledPage/KapperStatsPage';
import SubscriberStats from './pages/SettledPage/SubscriberStatsPage';
import LandingPage from './pages/LandingPage/LandingPage';
import PostPage from './pages/PostPage/PostPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ErrorBoundary from './services/ErrorBoundary';
import LoginRegisterModal from './components/login/LoginRegisterModal/LoginRegisterModal'; // Import modal
import './App.css';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('login');

  // Open the login/register modal
  const openLoginModal = (mode = 'login') => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  // Close the login/register modal
  const closeLoginModal = () => {
    setIsModalOpen(false);
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <div className="App">
          <ErrorBoundary>
            {/* Navbar at the top */}
            <Navbar openLoginModal={openLoginModal} />

            {/* Main Content */}
            <div className="main-content">
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/discover" element={<Discover />} />
                <Route exact path="/post" element={<PostPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/kapperstats" element={<KapperStats />} />
                <Route path="/subscriberstats" element={<SubscriberStats />} />
                <Route path="/:username" element={<KapperPage openLoginModal={openLoginModal} />} />

                {/* Catch-all route for unknown pages */}
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </div>

            {/* Footer at the bottom */}
            <footer className="footer">
              {/* <p>© 2024 Kapper.io - All Rights Reserved.</p> */}
            </footer>

            {/* Login/Register Modal */}
            {isModalOpen && (
              <LoginRegisterModal mode={modalMode} closeModal={closeLoginModal} />
            )}
          </ErrorBoundary>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

console.log('Google Client ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);

export default App;
