import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../../services/apiClient';
import './BankrollInput.css';

function BankrollInput() {
  const [bankroll, setBankroll] = useState(null);
  const [conversionRate, setConversionRate] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(true); // Checkbox state, default to checked
  const [initialValues, setInitialValues] = useState({
    bankroll: null,
    conversionRate: 1,
  });

  useEffect(() => {
const fetchData = async () => {
  try {
    const bankrollResponse = await apiClient.get('/stats/users/bankroll');
    const conversionRateResponse = await apiClient.get('/stats/kappers/conversionRate');

    const fetchedBankroll = bankrollResponse.data.success
      ? bankrollResponse.data.bankroll
      : null;
    const fetchedConversionRate = conversionRateResponse.data.success
      ? Math.min(Math.max(parseFloat(conversionRateResponse.data.conversion_rate), 1), 10)
      : 1;

    setBankroll(fetchedBankroll);
    setConversionRate(fetchedConversionRate);

    setInitialValues({
      bankroll: fetchedBankroll,
      conversionRate: fetchedConversionRate,
    });
  } catch (err) {
    console.error('[ERROR] Failed to fetch data:', err.message || err);
  }
};

const saveValues = async () => {
  try {
    if (autoUpdate) {
      await apiClient.post('/stats/users/bankroll', { bankroll });
      const rateToSave = Math.min(Math.max(conversionRate, 1), 10) / 100;
      await apiClient.post('/stats/kappers/conversionRate', { conversion_rate: rateToSave });
    }
    setInitialValues({
      bankroll,
      conversionRate,
    }); // Update initial values after saving
    setIsEditing(false);
  } catch (err) {
    console.error('[ERROR] Failed to save values:', err.message || err);
  }
};

    fetchData();
  }, []);

  const saveValues = async () => {
    try {
      if (autoUpdate) {
        await apiClient.post('/stats/users/bankroll', { bankroll });
        const rateToSave = Math.min(Math.max(conversionRate, 1), 10) / 100;
        await apiClient.post('/stats/kappers/conversionRate', { conversion_rate: rateToSave });
      }
      setInitialValues({
        bankroll,
        conversionRate,
      });
      setIsEditing(false);
    } catch (err) {
      console.error('[ERROR] Failed to save values:', err.message || err);
    }
  };

const cancelEditing = () => {
  setBankroll(initialValues.bankroll);
  setConversionRate(initialValues.conversionRate);
  setIsEditing(false);
};

const interpolateColor = (value, min, max, startColor, endColor) => {
  const ratio = (value - min) / (max - min);
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [bigint >> 16, (bigint >> 8) & 255, bigint & 255];
  };

  const rgbToHex = (rgb) =>
    `#${rgb
      .map((component) =>
        Math.round(component).toString(16).padStart(2, '0')
      )
      .join('')}`;

  const startRgb = hexToRgb(startColor);
  const endRgb = hexToRgb(endColor);

  const interpolatedRgb = startRgb.map(
    (start, i) => start + ratio * (endRgb[i] - start)
  );

  return rgbToHex(interpolatedRgb);
};

const calculateSliderThumbColor = (value) => {
  if (value <= 3) {
    // Interpolate between green (#00cc66) and yellow (#ffcc00)
    return interpolateColor(value, 1, 3, '#00cc66', '#ffcc00');
  } else if (value <= 7) {
    // Interpolate between yellow (#ffcc00) and red (#ff0000)
    return interpolateColor(value, 3, 7, '#ffcc00', '#ff0000');
  } else {
    // Return red (#ff0000) for values > 7
    return '#ff0000';
  }
};

  return (
    <div className="card" onClick={() => setIsEditing(true)}>
      <div className="bankroll-input-container">
        {/* Bankroll Section */}
        <div className="bankroll-input-header">
          <div className="bankroll-header">My Bankroll:
            {!isEditing ? (
              <span className="bankroll-value">
                {bankroll === null ? 'NA' : `$${bankroll}`}
              </span>
            ) : (
              <div className="bankroll-input-wrapper">
                <span className="bankroll-input-prefix">$</span>
                <input
                  type="number"
                  className="bankroll-input"
                  value={bankroll === null ? '' : bankroll}
                  min="1"
                  max="10000"
                  onChange={(e) =>
                    setBankroll(e.target.value ? parseInt(e.target.value, 10) : null)
                  }
                  placeholder="--"
                />
              </div>
            )}
          </div>
{!isEditing ? null : (
  <div className="bankroll-edit-buttons">
    {/* Other editing buttons or components */}
<button
  className="bankroll-cancel-button"
  onClick={(e) => {
    e.stopPropagation(); // Prevent parent click
    cancelEditing();
  }}
                >
                  
  Cancel
</button>
              <button className="bankroll-save-button" onClick={saveValues}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className="unit-converter-header">
          {isEditing && (
            <div>
              <div className="card-margin">
<label htmlFor="conversionRate" className="conversion-header">
  1 Unit = {`${conversionRate.toFixed(1)}%`} of Bankroll
</label>
                <div className="card-margin">
<input
  type="range"
  id="conversionRate"
  min="1"
  max="10"
  step="0.5"
  value={conversionRate}
  onChange={(e) =>
    setConversionRate(Math.min(Math.max(parseFloat(e.target.value), 1), 10))
  }
  className="slider"
  style={{
    '--thumb-color': calculateSliderThumbColor(conversionRate), // Use the dynamic color
  }}
/>
                </div>
              </div>
            </div>
          )}
        </div>
        {isEditing && (
          <div className="bankroll-edit-note">
            <label>
              <input
                type="checkbox"
                checked={autoUpdate}
                onChange={(e) => setAutoUpdate(e.target.checked)}
              />{' '}
              Automatically update bankroll. 
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default BankrollInput;
